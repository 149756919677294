@import './shared/styles/_vuetify-overrides.scss';


























































































.cassie-image-radio {
	display: flex;
	flex-direction: column-reverse;
	padding: 5px;

}
.cassie-image-radio-group .v-input--radio-group__input {
	display: flex;
	> :not(:last-child) {
		margin-right: 32px;
	}
}
.cassie-image-radio-image {
	width: 75px;
}
